// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-template-school-1-js": () => import("../src/template/school1.js" /* webpackChunkName: "component---src-template-school-1-js" */),
  "component---src-template-schools-js": () => import("../src/template/schools.js" /* webpackChunkName: "component---src-template-schools-js" */),
  "component---src-template-new-homes-js": () => import("../src/template/new_homes.js" /* webpackChunkName: "component---src-template-new-homes-js" */),
  "component---src-template-singlepost-js": () => import("../src/template/singlepost.js" /* webpackChunkName: "component---src-template-singlepost-js" */),
  "component---src-template-blog-list-js": () => import("../src/template/blogList.js" /* webpackChunkName: "component---src-template-blog-list-js" */),
  "component---src-template-homepage-1-js": () => import("../src/template/homepage1.js" /* webpackChunkName: "component---src-template-homepage-1-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-template-cities-js": () => import("../src/template/cities.js" /* webpackChunkName: "component---src-template-cities-js" */),
  "component---src-template-city-1-js": () => import("../src/template/city1.js" /* webpackChunkName: "component---src-template-city-1-js" */),
  "component---src-template-city-2-js": () => import("../src/template/city2.js" /* webpackChunkName: "component---src-template-city-2-js" */),
  "component---src-pages-2021-js": () => import("../src/pages/2021.js" /* webpackChunkName: "component---src-pages-2021-js" */),
  "component---src-pages-403-js": () => import("../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-reviews-js": () => import("../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-sell-js": () => import("../src/pages/sell.js" /* webpackChunkName: "component---src-pages-sell-js" */)
}

